*,
::after,
::before {
  box-sizing: border-box;
}

/*Start | Datatable layout modified*/
.dataTable {
  padding: 1px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 6px;
  transition: 300ms;
}

/* .dataTable tbody tr:hover {
  box-shadow: 0 0 1px 1px #eee !important;
  background-color: #eee !important;
  color: #000000;
} */

.dataTable tr.odd {
  height: 70px;
  background: #F9FAFB;
  border-radius: 10px !important;
  border: none !important;
  box-shadow: none;
  border-style: none !important;
  padding-bottom: 10px !important;
}
.dataTable tr.even {
  height: 70px;
  background: #F9FAFB;
  border-radius: 10px !important;
  border: none !important;
  box-shadow: none;
  border-style: none !important;
  padding-bottom: 10px !important;
}
.dataTable tbody, td, tfoot, th, thead, tr{
  border-style: none !important;
}
.dataTable tbody tr:hover td {
  color: #000000 !important;
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter {
  font-size: 0;
  display: none;
}

.dataTable.table>:not(:first-child) {
  border-top: 2px solid rgba(112, 118, 185, 0.35);
}

.dataTable tr th {
  letter-spacing: 0.25px;
  border: none;
  padding: 8px 14px !important;
  background-color: transparent !important;
  margin-bottom: 4px;
  text-transform: uppercase;
  position: relative;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
  text-align: center;
}

.dataTable tr td {
  font-size: 14.5px !important;
  color: #6c757d !important;
  transition: 300ms;
  text-align: center;
}

.dataTable.table>:not(caption)>*>* {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px transparent;
}

.dataTables_wrapper .dataTables_length select,
.dataTables_wrapper .dataTables_length input,
.dataTables_wrapper .dataTables_filter select,
.dataTables_wrapper .dataTables_filter input {
  display: block;
    width: 100%;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    appearance: none;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    padding: 0.25rem 0.5rem;
    font-size: .875rem;
    height: 45px;
    margin: 0 auto;
    border: 1px solid #DDDDE8;
    border-radius: 10px;
}

.dataTables_wrapper .dataTables_length select:focus,
.dataTables_wrapper .dataTables_length input:focus,
.dataTables_wrapper .dataTables_filter select:focus,
.dataTables_wrapper .dataTables_filter input:focus {
  color: #212529;
  background-color: #fff;
  border-color: #DDDDE8;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  box-shadow: none;
}

.dataTables_wrapper .dataTables_filter {
  display: inline-block;
  width: 100%;
}

.dataTables_wrapper .dataTables_filter label {
  width: 100%;
  max-width: 280px;
}

.dataTables_wrapper .dataTables_filter label input {
  background-image: url(../public/images/SearchIcon.svg) !important;
  background-repeat: no-repeat;
  background-size: 16px 27px;
  background-position: 10px 7px;
  padding-left: 33px;
}

.dataTables_wrapper .dataTables_length label {
  font-size: 14px;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.dataTables_wrapper .dataTables_length label select {
  margin: 0 6px;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right .22rem center;
  background-size: 14px 10px;
  padding-right: 20px;
  text-align-last: center;
  -moz-text-align-last: center;
}

.dataTables_wrapper .table {
  margin-bottom: 0;
  width: 100%;
}

.dataTables_wrapper .table thead .sorting {
  padding-right: 14px;
  position: relative;
}

/* .dataTables_wrapper .table thead .sorting::before,
.dataTables_wrapper .table thead .sorting::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 0;
  height: 0;
  right: 4px;
  margin-top: -6px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 6px solid currentColor;
} */

.dataTables_wrapper .table thead .sorting::after {
  margin-top: 2px;
  border-bottom: 4px solid transparent;
  border-top: 6px solid currentColor;
}

.dataTables_wrapper .table thead .sorting.sorting_asc::before {
  opacity: 1;
  border-bottom-color: #41c9d6;
  ;
}

.tableBg tr th:nth-child(2) {
  width: 190px !important;
}

.tableBg tr th:nth-child(1) {
  width: 100px !important;
}

.tableBg tr th:nth-child(3) {
  width: 350px !important;
}

.tableBg tr th:nth-child(6) {
  width: 180px !important;
}

.tableBg tr td span {
  color: #FFA800 !important;
  font-weight: 600 !important;
}

.dataTables_wrapper .table thead .sorting.sorting_desc::after {
  opacity: 1;
  border-top-color: #000000;
  ;
}

.dataTables_wrapper .dataTables_info {
  font-size: 14px;
}

.dataTables_wrapper .dataTables_paginate .ellipsis {
  padding: 0 7px;
  height: 30px;
  min-width: 30px;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: 1px;
  font-weight: bold;
  border: 1px solid transparent;
  margin-left: 2px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  height: 30px;
  min-width: 30px;
  text-decoration: none;
  display: inline-flex;
  border: none;
  margin-left: 6px;
  cursor: pointer !important;
  background-color: #f9fafb;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  font-size: 14px;
  color: #000000;
  font-weight: 400 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous,
.dataTables_wrapper .dataTables_paginate .paginate_button.next {
  font-weight: 400;
  padding: 0 20px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.next.disabled {
  cursor: not-allowed;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background-color: #fecc8d;
    color: #fff;
    font-weight: 700 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background-color: rgba(#324daa, 0.15);
  border-color: rgba(#324daa, 0.15);
}

.dataTables_wrapper .dataTables_processing {
  position: absolute;
  width: 100%;
  height: calc(100% - 43px);
  top: 43px;
  left: 0;
  background: rgba(255, 255, 255, 0.85);
  /* padding-top: 51px; */
  text-align: center;
  color: var(--blue);
  font-weight: 600;
  letter-spacing: 1px;
}

.dataTables_wrapper .dataTables_empty {
  text-align: center !important;
  color: red;
  /* padding: 50px 10px !important; */
  opacity: 0.75;
}

@media (max-width: 991.90px) {

  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dt-custom-filter {
    margin-bottom: 8px;
  }

  .dataTables_wrapper .dataTables_length {
    float: left;
  }

  .dataTables_wrapper .dataTables_filter label {
    max-width: 100%;
  }

  .dataTables_paginate {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

@media (max-width: 420px) {
  .dataTables_wrapper .dataTables_paginate .paginate_button {
    font-size: 8px;
    margin-left: 1px;
  }
}

.dataTables_processing::before {
  content: '';
  position: absolute;
  border-radius: 20px;
  top: 2px;
  left: 0;
  background: linear-gradient(to right, #edf2f9, #6477ba, #576fc7, #324daa) !important;
  width: 0;
  height: 3px;
  z-index: 9999;
  box-shadow: 0 0px 0 1px #fff;
  animation: tableloadingBar 4s linear infinite;
}

@keyframes tableloadingBar {
  0% {
    width: 0%;
  }

  50% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}

.dt-buttons {
  position: relative;
}

.dt-button-collection {
  position: absolute;
  width: 200px;
  top: 40px;
  left: 0;
  margin-top: 0 !important;
  background: #fff;
  box-shadow: 0 5px 14px #ccc;
  z-index: 10;
  padding: 15px;
  border-radius: 4px;
}

.dt-button-collection .dt-button {
  display: flex;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 15px;
  margin-bottom: 5px;
  font-size: 14px;
}

.dt-button-collection .dt-button.active,
.export-btn:hover {
  background: var(--blue);
  color: #fff;
  border: 1px solid var(--blue);
}

.dt-button-collection .dt-button:last-child {
  margin-bottom: 0;
}

.dt-button-collection-title {
  color: var(--bs-primary);
  font-size: 13px;
  text-align: left;
  margin-bottom: 8px;
}

table.dt-rowReorder-float {
  opacity: .1
}

div.dt-rowReorder-float-parent {
  table-layout: fixed;
  /* outline:2px solid #00000045; */
  outline-offset: -2px;
  z-index: 2001;
  position: absolute !important;
  overflow: hidden
}

tr.dt-rowReorder-moving {
  outline: 2px solid #324daa;
  outline-offset: -2px
}

body.dt-rowReorder-noOverflow {
  overflow-x: hidden
}

table.dataTable td.reorder {
  text-align: center;
  cursor: move
}

/* .dataTable tr td.drag {
  background-color: #576fc71f;
} */

.dataTable tr td.drag:hover {
  cursor: move;
}

/*End | Datatable layout modified*/